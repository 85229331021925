rivets = require 'rivets'
Collection = require 'models/base/collection'
moment = require 'moment'
_ = require 'underscore'
mediator = require 'mediator'
Backbone = require 'backbone'
Model = require 'models/base/model'
BaseModel = Backbone.Model
module.exports = class RivetsHelper

  setup: ->
    getKeyPathRoot = (model, keypath) ->
      keypath = keypath.split(":")
      model = model.get(keypath.shift())  while keypath.length > 1
      return { model: model, key: keypath.shift() }

    getterSetter = (model, keypath, value) ->
      root = getKeyPathRoot(model, keypath)
      model = root.model
      unless model instanceof BaseModel
        return model
      if arguments.length is 2
        if keypath is '_syncState' #hack for model.fetch hookup
          return model._syncState
        return model.get(root.key)
      model.set root.key, value
      return

    read = (obj, keypath) ->
      return obj[keypath]  if obj instanceof Backbone.Collection
      value = getterSetter(obj, keypath)

      # rivets cant iterate over Backbone.Collection -> return Array
      return value.models  if value instanceof Backbone.Collection
      value

    publish = (obj, keypath, value) ->
      if obj instanceof Collection
        obj[keypath] = value
      else
        getterSetter obj, keypath, value
      return

    onOffFactory = (action) ->
      (model, keypath, callback) ->
        return  unless (model instanceof BaseModel or model instanceof Collection)
        root = getKeyPathRoot(model, keypath)
        collection = root.model.get(root.key) || root.model
        if collection instanceof Collection
          collection[action] "add remove reset filter sort syncStateChange", callback
        else
          root.model[action] "change:" + root.key, callback
        return

    rivets.adapters[":"] =
      subscribe: onOffFactory("on")
      unsubscribe: onOffFactory("off")
      read: read
      publish: publish

    rivets.config.rootInterface = '>'
    rivets.adapters['>'] = rivets.adapters['.']
    delete rivets.adapters['.']

    rivets.formatters.pre = (value, pre) ->
        pre + value

    rivets.formatters.hasItems = (value) ->
      value? and value instanceof Array and value.length > 0

    rivets.formatters.fallback = (value, fb...) ->
      value || fb.join(' ')

    rivets.formatters.eq = (value, test) ->
      value?.toString() is test?.toString()
      
    rivets.formatters.any = (value, test...) ->
      _.some(test, (t)-> 
        value?.toString() is t?.toString()
      )

    rivets.formatters.fallback = (value, words...) ->
      if !value? || (typeof value is 'string' && value.trim() is "")
        return words.join ' '
      return value

    rivets.formatters.bracketify = (value) ->
      return "(#{value})" if !!value
      return ""

    rivets.formatters.toJSON = (value) ->
      return value.toJSON() if value.toJSON?
      return ""

    rivets.formatters.parseAsJson = (value) ->
      return {}unless value?
      return JSON.parse(value)

    rivets.formatters.before = (value, words...) ->
      words = words.join ' '
      return if value? then "#{words}#{value}" else ""

    rivets.formatters.after = (value, words...) ->
      words = words.join ' '
      return if value? then "#{value}#{words}" else ""

    rivets.formatters.date = (value, words...) ->
      return words.join ' ' unless value?
      return words.join ' ' if $.isEmptyObject(value)
      return new moment(value).format("DD/MM/YYYY")

    rivets.formatters.fromNow = (value) ->
      if not value?
        return words.join ' '
      if $.isEmptyObject(value)
        return words.join ' '
      moment(value).fromNow()

    rivets.formatters.calendar = (value) ->
      return unless value?
      moment.lang('en',
        calendar :
          lastDay : '[Yesterday]',
          sameDay : '[Today]',
          nextDay : '[Tomorrow]',
          lastWeek : '[last] dddd',
          nextWeek : 'dddd',
          sameElse : 'L'
      )

      return new moment(value).calendar()

    rivets.formatters.isPast = (value) ->
      if not value?
        return words.join ' '
      if $.isEmptyObject(value)
        return words.join ' '
      return if value.parseDate() < moment() then true else false

    rivets.formatters.age = (value) ->
      return moment().diff(value, 'years') + " years"
      return moment(value).fromNow(true);

    rivets.formatters.addDays = (value, days) ->
      return new Date() unless value?
      return new moment(value).add("days", parseInt(days) || 0).toDate()

    rivets.formatters.paymentStatus = (value) ->
      return "Unreconciled" if value is 'Unreconciled' or 'U'
      return "Part Reconciled" if value is 'PartReconciled' or 'P'
      return "Fully Reconciled" if value is 'FullyReconciled' or 'R'
      return ""

    rivets.formatters.invoiceStatus = (value) ->
      return "Unpaid" if value is'Unpaid'
      return "Part Paid" if value is 'PartPaid'
      return "Fully Paid" if value is 'FullyPaid'
      return value

    rivets.formatters.gender = (value) ->
      return 'Male' if value is 'M'
      return "Female" if value is 'F'
      return "Not Specified" if value is 'N'
      return value

    rivets.formatters.currency = (value, trim) ->
      symbol = mediator.provider.toJSON()?.currency
      result = symbol + parseFloat(value).toFixed(2)
      if result.indexOf(".00") > 0 and trim
        return symbol + parseFloat(value).toFixed(0)
      return result

    rivets.formatters.percentage = (value) ->
      return parseFloat(value).toFixed(2) + "%"

    rivets.formatters.toFixed = {
      read: (value, args) ->
        return parseFloat(value).toFixed(args)
      publish: (value, args)->
        return parseFloat(value)
    }

    rivets.formatters.capitalize = {
      read: (value) ->
        return value?.replace(/(?:^|\s)\S/g, (a) -> return a.toUpperCase());
      publish: (value)->
        return value?.replace(/(?:^|\s)\S/g, (a) -> return a.toUpperCase());
    }

    rivets.formatters.provider = (value) =>
      operator = if value.indexOf("?") < 0 then "?" else "&"
      return value + operator + "x-provider-name=#{mediator.provider.get('path')}"

    rivets.formatters.prepend = (value, pre) ->
      return pre + value

    rivets.formatters.append = (value, pre) ->
      return value + pre

    rivets.formatters.invert = (value) ->
      return !value

    rivets.formatters.trueif = (value, value2) ->
      if typeof value is "boolean"
        return value.toString() is value2
      return value is value2

    rivets.formatters.falseif = (value, value2) ->
      if typeof value is "boolean"
        return value.toString() isnt value2
      return value isnt value2

    rivets.formatters.isGreater = (value, value2) =>
      return value > parseInt(value2)

    rivets.formatters.isLess = (value, value2) =>
      return value < parseInt(value2)

    rivets.formatters.LTE = (value, value2) =>
      return value <= parseInt(value2)

    rivets.formatters.GTE = (value, value2) =>
      return value >= parseInt(value2)

    rivets.formatters.isEqual = (value, value2) ->
      return parseInt(value) is parseInt(value2)

    rivets.formatters.hasMore = (collection) ->
      console.log "hasMore"
      return false unless collection and collection.total?
      return collection.total > collection.length

    rivets.formatters.remainingCountOr = (collection, step) ->
      return 0 unless collection and collection.total?
      return Math.min(collection.total - collection.length, 25)

    rivets.formatters.limit = {
      read: (value, args) ->
        return value
      publish: (value, args...)->
        min = if args[0]? then parseFloat(args[0]) else -Infinity
        max = if args[1]? then parseFloat(args[1]) else Infinity

        return min if value < min || _.isNaN(value)
        return max if value > max
        return value
    }

    rivets.formatters.empty = (value) ->
      return not value?

    rivets.formatters.isBetween = (value, args...) ->
      return value >= parseInt(args[0]) and value <= parseInt(args[1])

    rivets.formatters.valueIn = (value, args...) ->
      valueIn = args.join ""
      return valueIn.indexOf(value ) >= 0

    rivets.formatters.hasItems = (value, min, max) ->
      return false unless value? and _.isArray(value)
      min = parseInt(min || 1)
      max = parseInt(max || value.length)
      return !(value.length < min || value.length > max)

    rivets.formatters.length = (value) ->
      return 0 unless value? and _.isArray(value)
      return value.length

    rivets.formatters.min = (value, other) ->
      Math.min(value || 0, other || 0)

    rivets.formatters.toArray = (collection) ->
      return [] if !collection?
      collection.models

    rivets.formatters.firstValues = (collection) ->
      if collection? and collection.length > 0
        return collection[0].values
      return []

    rivets.formatters.modelAt = (collection, idx, prop) ->
      idx = parseInt(idx)
      if collection? && collection.length > 0 && collection.length > idx
        obj = collection[idx].toJSON()
        return if prop? then obj[prop] else obj
      return null

    rivets.formatters.appointmentStatus = (value) =>
      switch value
        when 0, 7
          return "Booked"
        when 1
          return "Arrived"
        when 2
          return "Complete"
        when 3
          return "DNA"
        when 4
          return "Cancelled"

    rivets.formatters.practitionerOption = (value) =>
      return "#{value.get('name')} (#{value.get('gender')})"

    rivets.formatters.stringToBool = (value) ->
      return value is "true"  || value is true

    rivets.formatters.toExternalLink = (value) ->
      return "" unless value?
      return value if value.substring(0, 7) is 'http://'
      return "http://#{value}"

    rivets.formatters.YesNo = (value, yes_word, no_word) ->
      return yes_word if value
      return no_word

    rivets.formatters.YesNoPhrase = (value, args...) ->
      phrases = args.join(" ").split("::")
      return phrases[0] if value is true
      return phrases[1]

    rivets.formatters.dateFormat = (value, args...) =>
      return null unless value?
      return new moment(value).format(args.join(' '))

    rivets.formatters.dateOnly = (value) =>
      return null unless value?
      if typeof value is "string"
        regex = /^\d{4}\-\d{2}\-\d{2}/
        val = value.match(regex)
        return null if val.length < 1
        return new moment(val[0]).toDate()

      return value

    rivets.formatters.status = (value) =>
      if value == "InProgress"
        return "In Progress"
      else
        return value

    rivets.formatters.toKeyValuePair = (value) =>
      dictionary = []
      _.each(_.pairs(value), (kvp) =>
        dictionary.push({ key:kvp[0], value:kvp[1] })
      )
      return dictionary

    rivets.formatters.isToday = (value) =>
      val = new moment(value)
      today = new moment()

      return false unless value? && val.isValid()
      return val.isSame(today, "day")

    rivets.formatters.toTimeOfDay = (value) =>
      val = new moment(value)
      date = new moment(value).startOf("day")

      date.add("hour", 12)
      return "morning" if val.toDate() < date.toDate()
      date.add("hour", 6)
      return "evening" if val.toDate() >= date.toDate()
      return "afternoon"

    rivets.formatters.daysInMonth = (value) ->
      return 0 unless value?
      return new moment(value).daysInMonth();

    rivets.formatters.toFriendlyDay = (value) =>
      val = new moment(value).startOf("day")
      diff = val.diff(new moment().startOf("day"), "day")

      return val.format("dddd") if diff < 0 || diff > 1
      return "Today" if diff is 0
      return "Tomorrow" if diff is 1

    rivets.formatters.toDay = (value) =>
      return "" unless value >= 0 && value < 7
      return new moment().day(value).format("dddd")

    rivets.formatters.toDate = (value) =>
      return "" unless value > 0
      return new moment().date(value).format("Do")

    rivets.formatters.toNumeric = (value) =>
      return if $.isNumeric(value) then parseFloat(value) else NaN

    rivets.formatters.toString = (value) =>
      return value.toString()

    rivets.formatters.substring = (value, args...) =>
      return value.substring(parseInt(args[0]), parseInt(args[1]))

    rivets.formatters.flatText = (value) =>
      return value.replace(/\n/g, " ")

    rivets.formatters.nullOrWhiteSpace = (value)=>
      return !value? or value.trim().length < 1

    rivets.formatters.nullOrUndefined = (value) =>
      return !value?

    rivets.formatters.hasProperty = (value, args) =>
      return value? and args? and value[args]?

    rivets.formatters.toLower = (value) ->
      return "" unless value?
      return value.toLowerCase()

    rivets.formatters.uncamel = (s) ->
      return "" if !s
      return s
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) -> str.toUpperCase())

    rivets.formatters.toColour = (c) =>
      return unless c?
      keys = ["r", "g", "b", "a"]
      vals = []

      if(c.substring(0, 3).toLowerCase() is "rgb")
        vals = c.substring(c.indexOf("(") + 1, c.lastIndexOf(")")).split(",")
        vals.push("255") if vals.length < 4

        for i in [0..3]
          vals[i] = parseInt(vals[i])
        return _.object(keys, vals)

      c = c.substring(1, c.length) if (c.charAt(0) is '#')

      for i in [0..(c.length/2)-1]
        vals.push(parseInt(c.substring(i*2, (i*2)+2), 16))
      vals.push(255) if vals.length < 4
      return _.object(keys, vals)

    rivets.formatters.toHSL = (c) =>
      return unless c?
      c.r /= 255
      c.g /= 255
      c.b /= 255
      max = Math.max(c.r, c.g, c.b)
      min = Math.min(c.r, c.g, c.b)
      l = (max + min) / 2
      if max == min
        h = s = 0
      else
        d = max - min
        s = if l > 0.5 then d / (2 - max - min) else d / (max + min)
        switch max
          when c.r
            h = (c.g - c.b) / d + (if c.g < c.b then 6 else 0)
          when c.g
            h = (c.b - c.r) / d + 2
          when c.b
            h = (c.r - c.g) / d + 4
        h /= 6

      return {h:h, s:s, l:l}

    rivets.formatters.fromHSL = (c) =>
      return unless c?
      hue2rgb = (p, q, t) ->
        t += 1 if t < 0
        t -= 1 if t > 1

        if t < 1 / 6
          return p + (q - p) * 6 * t
        if t < 1 / 2
          return q
        if t < 2 / 3
          return p + (q - p) * (2 / 3 - t) * 6
        return p

      if c.s == 0
        r = g = b = c.l
      else
        q = if c.l < 0.5 then c.l * (1 + c.s) else c.l + c.s - c.l * c.s
        p = 2 * c.l - q
        r = hue2rgb(p, q, c.h + 1 / 3)
        g = hue2rgb(p, q, c.h)
        b = hue2rgb(p, q, c.h - (1 / 3))

      return {
        r: Math.round(r * 255)
        g: Math.round(g * 255)
        b: Math.round(b * 255)
      }

    rivets.formatters.toRGBA= (c) =>
      return unless c?
      c.a = 255 unless c.a?
      return "rgba(#{c.r}, #{c.g}, #{c.b}, #{c.a})"

    rivets.formatters.lightenHSL = (c, percentage) =>
      return unless c?
      percentage = percentage || 0
      c.l = Math.min(c.l + (percentage/100), 1)
      return c

    rivets.formatters.darkenHSL = (c, percentage) =>
      return unless c?
      percentage = percentage || 0
      c.l = Math.max(c.l - (percentage/100), 0)
      return c

    rivets.formatters.lighten = (c, percentage) =>
      return unless c?
      percentage = percentage || 0
      c.r = Math.min(c.r + Math.round(percentage * 2.55), 255)
      c.g = Math.min(c.g + Math.round(percentage * 2.55), 255)
      c.b = Math.min(c.b + Math.round(percentage * 2.55), 255)
      return c

    rivets.formatters.darken = (c, percentage) =>
      return unless c?
      percentage = percentage || 0
      c.r = Math.max(c.r - Math.round(percentage * 2.55), 0)
      c.g = Math.max(c.g - Math.round(percentage * 2.55), 0)
      c.b = Math.max(c.b - Math.round(percentage * 2.55), 0)
      return c

    rivets.formatters.commaSeparate = (value) ->
      if value instanceof Array
        return value.join(", ").slice 0, -2
      return ""

    rivets.formatters.toList = (value) =>
      return unless (value? && value instanceof Array && value.length > 0)
      return value[0] if value.length is 1
      return value.slice(0, value.length-1).join(", ") + " and " + value[value.length-1]

    rivets.formatters.join = (value, prop, seperator) ->
      seperator ?= ", "
      return _.pluck(value, prop).join(seperator)

    rivets.formatters.modelize = (value) ->
      if value instanceof Array
        new Collection(value).models
      else
        new Model(value)

    rivets.formatters.dekey = (svc) ->
      c = new Collection
      for key in _.keys(svc)
         c.add({id:key, name:svc[key]})
      c.models

    rivets.formatters.invoiceStatus = (value) ->
      return if value == 'P' then 'Paid' else 'Unpaid'

    rivets.formatters.formatInvoicePeriod = (value) ->
      if value?
        return value.name + " (Running on #{value.dayOfWeek} every #{value.intervalInWeeks} weeks)"

    rivets.formatters.filepath = (value) =>
      return value.replace(/\//g,"%2f")
                  .replace(/\\/g,"%2f")

    #Binders

    rivets.binders.selected = (el, value) =>
      return false unless value?
      val = el.value || el.getAttribute("value")
      if value.toString() is val
        el.setAttribute("selected", "selected")
      else
        el.removeAttribute("selected")

    rivets.binders.emphasize = (el, value) ->
      return false unless value?
      if value.toString() is el.getAttribute("index")
        el.classList.add("emphasize")
      else
        el.classList.remove("emphasize")

    rivets.binders.min = (el, value) =>
      return unless value?
      value = parseFloat(value)
      el.min = value
      if value > el.value
        el.value = value
        $(el).trigger("change")

    rivets.binders.active = (el, value)=>
      index = el.getAttribute("index")

      if value? && value.toString() is index
        el.classList.add("active")
        $(el).find(".item-body").slideDown()
      else
        el.classList.remove("active")
        $(el).find(".item-body").slideUp()

    rivets.binders.validate = (el, value) ->
      $el = $(el)
      $el.removeClass("valid invalid unvalidated")

      tooltips = Foundation.libs.tooltip

      $input = $el.find('input, select')

      if (!value? || value.isValid is true)
        $el.addClass(if !value? then "unvalidated" else "valid")
        $tip = tooltips.getTip($input)

        $input.removeClass("has-tip tip-top open")
              .removeAttr("data-tooltip")
              .removeAttr("data-selector")
              .removeData()

        $tip?.remove();

        return

      if value.isValid is false
        $el.addClass("invalid")

        unless mediator.device.get("isTouch")
          $input.addClass("has-tip tip-top")
          $input.attr("data-tooltip": "")
          $input.attr(title: value.message)
          tooltips.init()
        else
          $el.find(".error-message > .message").text(value.message)

        return

    rivets.binders["style-*"] = (el, value) ->
      return unless value?
      $(el).css(@args[0], value)

    rivets.binders.sort = (el, value) ->
      $el = $(el)
      key = $el.data("sort-key")

      if (key is value)
        $("[data-sort]").find(".sort-indicator").remove()
        $el.append('<div class="sort-indicator asc"/>')
      if (key + " DESC" is value)
        $("[data-sort]").find(".sort-indicator").remove()
        $el.append('<div class="sort-indicator desc"/>')

    rivets.binders.availability = (el, value) ->
      $el = $(el)
      period = $el.parents("[data-period]").data("period")
      $el.removeClass("success secondary disabled")

      if period?
        date = new moment($el.attr("data-date")).startOf("day")
        mStart = new moment(date)
        mEnd = new moment(date)

        switch period
          when "morning"
            _start = mStart.toDate()
            _end = mEnd.add("hour", 12).toDate()
          when "afternoon"
            _start = mStart.add("hour", 12).toDate()
            _end = mEnd.add("hour", 18).toDate()
          when "evening"
            _start = mStart.add("hour", 18).toDate()
            _end = mEnd.endOf("day").toDate()

        any = _.some(value, (appt)=>
          start = new moment(appt.get("startDateTime")).toDate()
          return start < _end && start >= _start && start > new Date()
        )

      unless any
        el.innerHTML = "<div>
                          <div>No</div>
                          <div class='show-for-tablet-up'>
                            <div>Appointments</div>
                            <div>Available</div>
                          </div>
                          <div class='show-for-phone-only'>
                            <div>Appts</div>
                          </div>
                        </div>"
        $el.addClass("secondary disabled")
      else
        el.innerHTML = "<div>
                          <div class='unit'>
                            <i class='fa fa-check'></i>
                          </div>
                          <div class='show-for-tablet-up'>
                            <div>Appointments</div>
                            <div>Available</div>
                          </div>
                          <div class='show-for-phone-only'>
                            <div>Appts</div>
                          </div>
                        </div>"
        $el.addClass("success")
