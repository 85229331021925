$ = require 'jquery'
_ = require 'underscore'
mediator = require 'mediator'

AuthHelper = require 'lib/auth-helper'
Controller = require './controller'

module.exports = class NonAuthController extends Controller
  beforeAction: (p, r, o)->
    super
    if mediator.user.get('stateUnknown')
      # if state is unknown then the page is loading
      # fresh and will not have a booking object
      # window.location.pathname || window.location.hash
      authHelper = new AuthHelper()
      _.get(
        url:'/api/user/current'
        success: (res)=>
          if res.isAuthenticated is true
            authHelper.done(res)
          else
            mediator.user.set
              isAuthenticated:false
              stateUnknown:false
              permissions: []
        error: =>
          mediator.user.set
            isAuthenticated:false
            stateUnknown:false
            permissions: []
      )
