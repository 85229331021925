Chaplin = require 'chaplin'
SiteView = require 'views/site-view'
HeaderView = require 'views/home/header-view'

_ = require 'underscore'
mediator = require 'mediator'
load = require('scriptloader');

GoogleAnalyticsPath = "https://www.googletagmanager.com/gtag/js?id="

LoadGoogleAnalytics = (googleTrackingId) ->
  return if !googleTrackingId;

  fullpath = "#{GoogleAnalyticsPath}#{googleTrackingId}"
  return if $("script[src='#{fullpath}']").length > 0

  $("script[src*='#{GoogleAnalyticsPath}'").remove();
  load(fullpath, -> 
    gtag('js', new Date());
    gtag('config', googleTrackingId);
  )

module.exports = class Controller extends Chaplin.Controller
  menuTitle: ''

  initialize: =>
    super
    $("body").removeClass((index, css) -> return (css.match(/(^|\s)bg-\S+/g) || []).join(' '))
             .addClass("bg-#{@menuTitle}")

  beforeAction: (p, r, o)->
    super
    @reuse 'site', SiteView
    @reuse 'header', HeaderView, region: 'header'

    unless p.provider?
      console.error "Valid provider name has not been provided"
      # goto 404
      return

    provider = mediator.provider.toJSON()
    if p.provider isnt provider.path
      mediator.provider.clear()
      mediator.provider.set
        path: p.provider

      _.get(
        url: "/api/system/provider/info"
        success: (res)=>
          res.message = sanitizeHtml(res.message,  { allowedTags,  allowedAttributes});
          mediator.provider.set(res)
          $("body").addClass("skin-#{res.skin || 'default'}")
          LoadGoogleAnalytics(res.googleTrackingId)
        error: (res)=>
          if res.status is 401
            Chaplin.utils.redirectTo(
              controller: 'modules/booking/searching',
              action: 'service',
              params: { provider: p.provider }
            )
          else
            mediator.provider.clear()

            Chaplin.utils.redirectTo(
              controller: 'modules/home/home',
              action: 'notfound'
            )
      )
