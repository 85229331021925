var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"row\"><ul class=\"breadcrumbs\"><li><a rv-href=\"provider:path | before / | after /booking/service\">Service</a></li><li><a rv-href=\"provider:path | before / | after /booking/availability\">Availability</a></li><li><a rv-href=\"provider:path | before / | after /booking/problem\">Problem</a></li><li class=\"current\"><a>Payment</a></li><li class=\"unavailable\"><a>Confirmation</a></li></ul><table><tbody><tr><td style=\"width:50%\"><a rv-on-click=\"vm&gt;select\" style=\"height:100%\" class=\"button paymentselection radius expand\"><p class=\"fa-lg\">Self Pay</p><p>I pay for my own treatments</p></a></td><td style=\"width:50%\"><a style=\"height:100%\" class=\"button paymentselection button radius\"><p class=\"fa-lg\">Insured</p><p>I am insured or am a member of a club or group who pays for my treatments</p></a></td></tr></tbody></table></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}