Collection = require 'models/base/collection'
moment = require 'moment'
_ = require 'underscore'

module.exports = class SessionCollection extends Collection
  url: => "/api/pronto/online/sessions"

  initialize: =>
    super
    @filterEndDate = new moment()
    @dates = []
    @hasLess = false
    @hasMore = false
    @sort_key = 'date'
    @listenTo @, 'sort', @nextDate

  getDistinctDates: =>
    return _.uniq(
      @map((m)=>
        return m.get("date").match(/^\d{4}\-\d{2}\-\d{2}/)[0]
      ).sort(), true
    )

  nextDate: =>
    @dates = []
    res = []
    all_dates = @getDistinctDates()

    i = 0

    while i < all_dates.length and @dates.length < 4
      date = new moment(all_dates[i]).toDate()
      @dates.push(all_dates[i]) if date >= @filterEndDate.startOf('day').toDate()
      i++

    @setStats(all_dates)

  prevDate: =>
    @dates = []
    res = []
    all_dates = @getDistinctDates()

    i = all_dates.length - 1
    while i > -1 and @dates.length < 4
      date = new moment(all_dates[i]).toDate()
      @dates.push(all_dates[i]) if date <= @filterStartDate.startOf('day').toDate()
      i--

    @dates.reverse()
    @setStats(all_dates)

  setStats: (all_dates)=>
    if @dates.length > 0
      last = _.last(@dates)
      @filterStartDate = new moment(@dates[0])
      @filterEndDate = new moment(last)
      @hasMore = all_dates.indexOf(last) < all_dates.length - 1
      @hasLess = all_dates.indexOf(@dates[0]) > 0
    @trigger 'filter'
