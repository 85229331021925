var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"row\"><h3>In order to help us prepare for your visit, can you please provide us with the reason for your appointment.</h3><div><label>The area affected</label><select rv-value=\"model:bodySite\"><option value=\"\">Select Body Area</option><option rv-each-bodysite=\"bodysites:models\" rv-text=\"bodysite:value\" rv-value=\"bodysite:value\"></option></select></div><div><label>What Happened?</label><select rv-value=\"model:injuryCause\"><option value=\"\">Select What Happened</option><option rv-each-cause=\"causes:models\" rv-text=\"cause:value\" rv-value=\"cause:value\"></option></select></div><div><label>How long have you had this problem?</label><div class=\"row\"><div class=\"column small-6\"><input type=\"number\" rv-value=\"model:timePresent\" min=\"1\" value=\"1\"/></div><div class=\"column small-6\"><select rv-value=\"model:timePresentUnit\"><option value=\"Days\">Day(s)</option><option value=\"Weeks\">Week(s)</option><option value=\"Months\">Month(s)</option><option value=\"Years\">Year(s)</option></select></div></div></div><div><label>Other information</label><textarea rows=\"10\" style=\"resize:none\" rv-value=\"model:clinicNotes\" placeholder=\"Type anything here that might be useful for the practitioner\"></textarea></div><div class=\"clearfix\"><span class=\"right\"><button rv-on-click=\"vm&gt;skip\" class=\"button\"><span>Skip&nbsp;</span><i class=\"fa fa-forward\"></i></button>&nbsp;<button rv-on-click=\"vm&gt;select\" class=\"button\">Next</button></span></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}