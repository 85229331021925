Collection = require 'models/base/collection'
mediator = require 'mediator'

module.exports = class LocationCollection extends Collection
  url: =>
    base = '/api/pronto/locations'
    serviceId = mediator.booking?.get("service.networkServiceId")
    networkId = mediator.booking?.get("service.networkId")
    query = "?networkId=#{networkId}&serviceId=#{serviceId}"
    return base + query if serviceId? && networkId?
    return base