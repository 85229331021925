View = require 'views/base/view'
Model = require 'models/base/model'
mediator = require 'mediator'

alert = require 'lib/alert'
AuthHelper = require 'lib/auth-helper'

_ = require 'underscore'

module.exports = class ResetPasswordScreen extends View
  template: require './template'
  validator: require './validator'

  optionNames: View::optionNames.concat ['process']

  initialize: =>
    super
    @model = new Model()
    @view = new Model
      submitted:false
      mode: if @process? then "reset" else "change"

  submit: =>
    @view.set(submitted:true)
    return unless @isValid()

    _.post(
      url: "/api/user/online/update"
      data:
        password: @model.get("password")
        resetCode: @process

      success: (res)=>
        @model.set(res)
        alert(@, "Password Change Successful", "Your password has been successfully changed", @login)

      error: (xhr, err, status)=>
        alert(@, "Login Failed", xhr?.responseJSON?.responseStatus?.message || "", () =>)
    )

  login: =>
    if mediator.user.get("isAuthenticated")
      @redirectTo(
        controller: 'modules/booking/searching',
        action: 'service',
      )
      return

    _.post
      url: "/api/auth/credentials"
      data: @model.toJSON()
      success: (res)=>
        authHelper = new AuthHelper("/#{mediator.provider.get('path')}/booking/service")
        authHelper.done(res)

      error: (xhr, err, status) =>
        alert(@, "Login Failed", xhr?.responseJSON?.responseStatus?.message || "", () =>)
