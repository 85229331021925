Chaplin = require 'chaplin'
mediator = require 'mediator'
View = require 'views/base/view'
$ = require 'jquery'

Model = require 'models/base/model'
DeepModel = require 'models/base/deep_model'
Collection = require 'models/base/collection'

class BodySites extends Collection
  url: "/api/pronto/lookup/bodysite"

class InjuryCauses extends Collection
  url: "/api/pronto/lookup/cause"

module.exports = class ProblemScreen extends View
  template: require './template'

  initialize: =>
    super
    @model ?= new Model
      timePresent: 1
      timePresentUnit: "Days"

    @bodysites = new BodySites()
    @causes = new InjuryCauses()

    $.when(
      @bodysites.fetch()
      @causes.fetch()
    ).then =>
      @publishEvent 'loading:finish'

  extraBindings: =>
    causes: @causes
    bodysites: @bodysites

  skip: =>
    @redirectTo(
      controller: 'modules/booking/booking',
      action: 'payment'
    )

  select: (e, v)=>
    mediator.booking.set
      problem:v.model.toJSON()

    @skip()
