Chaplin = require 'chaplin'
mediator = require 'mediator'

Model = require 'models/base/model'
DeepModel = require 'models/base/deep_model'
RivetsHelper = require 'lib/rivets'
AjaxHelper = require 'lib/ajax-helper'
GuidHelper = require 'lib/guid-helper'
DeepExtend = require 'lib/underscore.mixin.deepExtend'
jqueryNumeric = require 'lib/jquery.number'
$ = require 'jquery'

User = require 'models/common/user'
# The application object.

module.exports = class Application extends Chaplin.Application
  start: ->
    # You can fetch some data here and start app
    # (by calling `super`) after that.
    Foundation.libs.reveal.settings.close_on_background_click = false;
    
    new RivetsHelper().setup()
    new AjaxHelper().setup()
    new GuidHelper().setup()
    super
    $(document).ajaxError (err) ->
        Airbrake?.push(error:err)

  initMediator: ->
    DeepExtend()
    mediator.user = new User()
    mediator.provider = new DeepModel()
    mediator.booking = new DeepModel()
    mediator.device = new Model
      isTouch: 'ontouchstart' of window
    super
