$ = require 'jquery'
# Hookup jQuery to Backbone.
Backbone = require 'backbone'
Backbone.$ = $
require('lib/deep-model')
window.$ = window.jQuery = $
foundation = require 'foundation'

# Initialize the application on DOM ready event.
Application = require 'application'
routes = require 'routes'
$ ->
  $(document).foundation()
  new Application {
    title: 'Pronto Portal',
    controllerSuffix: '_controller',
    controllerPath:'',
    routes
  }
