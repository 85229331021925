var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<h3>My Bookings</h3><div rv-hide=\"history:models | hasItems\" class=\"row\"><div>There are no items to display.</div></div><div rv-each-appointment=\"history:models\" style=\"padding:10px\" rv-class-booked=\"appointment:status | any 0 7\" rv-class-arrived=\"appointment:status | eq 1\" rv-class-completed=\"appointment:status | eq 2\" rv-class-dna=\"appointment:status | eq 3\" rv-class-cancelled=\"appointment:status | eq 4\" class=\"row appointment\"><div class=\"column small-9\"><h4 rv-text=\"appointment:startDateTime | dateFormat dddd Do MMMM YYYY\"></h4><h5><div class=\"row\"><div rv-text=\"appointment:startDateTime | dateFormat h:mma\" class=\"column small-6\"></div><div rv-text=\"appointment:totalPrice | currency\" class=\"column small-6\"></div></div></h5><div class=\"row\"><div class=\"column small-2\">At</div><div class=\"column small-10\"><strong rv-text=\"appointment:locationName\"></strong></div></div><div class=\"row\"><div class=\"column small-2\">With</div><div class=\"column small-10\"><strong rv-text=\"appointment:name\"></strong></div></div></div><div class=\"column small-3\"><div class=\"right\"><h5 rv-text=\"appointment:status | appointmentStatus\" class=\"right\"></h5><ul class=\"button-group\"><li><div rv-on-click=\"vm&gt;cancel\" rv-show=\"appointment:status | any 0 7\" class=\"button small\"> <span>Cancel </span><span class=\"show-for-medium-up\">&nbsp;Appointment</span></div></li></ul></div></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}