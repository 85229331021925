Model = require 'models/base/model'
DialogView = require 'views/base/dialog_view'

class ConfirmDialog extends DialogView
  template:require './templates/ConfirmDialog'
  
  confirm: =>
    @publishEvent 'confirm:confirmed'
    @close()

module.exports = (view, title, message, callback) ->
  view.subscribeEvent 'confirm:confirmed', callback
  view.subview 'confirmDialog', new ConfirmDialog
    model: new Model
      title:title
      message:message
