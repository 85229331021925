var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<h2>Confirm Cancellation.</h2><hr/><p class=\"lead\">Are you sure you want to make this cancellation?</p><p rv-html=\"view:policy\"></p><p>By cancelling an appoinment this may invoke a cancellation charge.</p><hr/><div class=\"right\"><button rv-on-click=\"vm&gt;close\" class=\"link small\">No</button><button rv-on-click=\"vm&gt;cancel\" class=\"button small\">Yes</button></div><a class=\"close-reveal-modal\">&#215;</a>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}