DeepModel = require 'models/base/deep_model'
AuthController = require 'controllers/base/auth_controller'

EditScreen = require 'modules/client/views/edit-screen'
SummaryScreen = require 'modules/client/views/summary-screen'

module.exports = class ClientController extends AuthController
  menuTitle: 'details'

  summary: ->
    @view = new SummaryScreen 
      region: 'main'

  edit: ->
    @view = new EditScreen 
      region: 'main'