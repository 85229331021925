_ = require 'underscore'
Model = require 'models/base/model'
mediator = require 'mediator'

DialogView = require 'views/base/dialog_view'

module.exports = class CancellationDialog extends DialogView
  template: require './template'

  initialize: =>
    @view = new Model

    _.get
      url: "/api/pronto/content/bykey/cancellation-policy"
      success: (res) => @view.set(policy: res.content)

  cancel: =>
    @model.set(customerID: mediator.user.get("client.id"))
    _.post(
      url: "/api/appointments/#{@model.get('id')}/cancel"
      data: @model.toJSON()
      success: (model, response) =>
        @publishEvent "appointment:cancelled", model
        @close()
      error: =>
        console.log "errored"
    )
