NonAuthController = require 'controllers/base/non_auth_controller'

SupportScreen = require 'modules/support/views/support-screen'

module.exports = class SupportController extends NonAuthController
  menuTitle: 'support'

  support: (p, r, o)->
    @view = new SupportScreen
      region: 'main'
