View = require 'views/base/view'
Collection = require 'models/base/collection'

_ = require 'underscore'
mediator = require 'mediator'

module.exports = class InvoiceScreen extends View
  template: require './template'

  initialize: =>
    super
    @invoices = new Collection()
    @invoices.url = "/api/pronto/invoices/#{mediator.user.get('client')?.id}"
    @invoices.comparator = 'reference'
    #@history.comparator = 'startDateTime desc'
    @invoices.fetch(
      success: (collection) =>
        collection.sort()
    )

  extraBindings: =>
    invoices: @invoices
