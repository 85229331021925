Chaplin = require 'chaplin'
rivets = require 'rivets'
_ = require 'underscore'
mediator = require 'mediator'

module.exports = class View extends Chaplin.View
  # Auto-save `template` option passed to any view as `@template`.
  optionNames: Chaplin.View::optionNames.concat ['template', 'model']
  template: =>
    debugger

  autoRender:true
  autoAttach:true

  extraBindings: -> {}

  constructor: ->
    _(@).extend @validator.prototype if @validator?
    super
    @subviews ?= []

  initialize: (options) ->
    # This is standard in new chaplin
    super

    if _.isFunction(@applyRules)
      @applyRules()

    @on 'addedToDOM', @applyFocus

  # Precompiled templates function initializer.
  getTemplateFunction: ->
    @template

  renderSubviews: ->
    return

  render: ->
    super
    @bindings.unbind() if @bindings?
    if _.isFunction(@applyGraphics)
      @applyGraphics()

    @applyBindings()

    unless @renderedSubviews
      @renderSubviews()
      @renderedSubviews = yes

  attach: =>
    super
    @$el.foundation()

  applyBindings: =>
    hash = _.defaults @extraBindings(),
      model:@model
      vm:@
      view:@view
      provider: mediator.provider
    @bindings = rivets.bind @$el, hash

  dispose: =>
    @bindings.unbind()
    @validator.clearRules() if @validator?.clearRules?
    @clearRules() if @clearRules
    super

  redirectTo:(args)=>
    args.params = _.extend(args.params || {}, {provider: mediator.provider?.get("path")})
    Chaplin.utils.redirectTo(args)

  stopPropagation: (e)=>
    e.preventDefault()
    e.stopPropagation()

  immediateSync: (e) =>
    $target = @$(e.target)

    if $target.hasClass("money") || $target.hasClass("percentage")
      $target.val(0)  if _.isNaN(parseFloat(e.target.value.replace(",", "")))

    $target.trigger("change")

  sync: (e) =>
    $target = @$(e.target)

    if $target.hasClass("money") || $target.hasClass("percentage")
      $target.val(0)  if _.isNaN(parseFloat(e.target.value))

    $target.trigger("change")

  syncOrSubmit: (e) =>
    if e.keyCode is 13
      return @[e.currentTarget.dataset["submit"]]()

    @sync(e)

  applyFocus: =>
    @$el.find(".initial-focus:visible").first().focus()

  money: (selector)=>
    return unless @$el?
    symbol = mediator.user.get("systemSettings")?["currencySymbol"] || "£"
    selector ?= '.money'

    @$(selector)
      # .on 'mousedown', (e) =>
      #   if @$(e.currentTarget).val() is '0'
      #     @$(e.currentTarget)[0].setSelectionRange(1,1)
      #     return false
      .number( true, 2 )
      .wrap("<span class='moneyWrap'></span>").parent().append("<span>#{symbol}</span>")
