Chaplin = require 'chaplin'
mediator = require 'mediator'

DeepModel = require 'models/base/deep_model'
AuthController = require 'controllers/base/auth_controller'

ProblemScreen = require 'modules/booking/views/problem-screen'
PaymentScreen = require 'modules/booking/views/payment-screen'
ConfirmationScreen = require 'modules/booking/views/confirmation-screen'

module.exports = class BookingController extends AuthController
  menuTitle: 'booking'

  beforeAction: (p, r, o)->
    super
    return if r.action is "service"

    unless mediator.booking.has("service")
      Chaplin.utils.redirectTo(
        controller: 'modules/booking/searching',
        action: 'service',
        params:
          provider: p.provider
      )

    clinical = mediator.provider.get('clinical') is true
    payments = mediator.provider.get('payments') is true

    r.action = "payment" if r.action is "problem" && !clinical
    r.action = "confirmation" if r.action is "payment" && !payments

    r.name = "modules/booking/booking##{r.action}"
    r.path = mediator.provider.get('path') + "/booking/#{r.action}"

  problem: ->
    @view = new ProblemScreen region: 'main'

  payment: ->
    @view = new PaymentScreen region: 'main'

  confirmation: ->
    @view = new ConfirmationScreen region: 'main'
