mediator = require 'mediator'
Chaplin = require 'chaplin'
_ = require 'underscore'

module.exports = class AuthHelper
  constructor: (redirectUrl)  ->
    @redirectUrl = redirectUrl

  done: (res) =>
    if res.isAuthenticated
      mediator.user.set
        name: res.displayName
        email: res.email
        isAuthenticated:true
        stateUnknown:false
        displayName: res.displayName
        userName: res.userName
        client: res.client

      Chaplin.utils.redirectTo url: @redirectUrl if @redirectUrl?
    else
      if mediator.user?
        mediator.user.set
          isAuthenticated:false
          stateUnknown:false
      url = window.location.href || window.location.pathname
      _.post(url:'/api/auth/logout');
      return false

    mediator.user.trigger 'change'
