View = require 'views/base/view'
$ = require 'jquery'
Chaplin = require 'chaplin'

mediator = require 'mediator'
moment = require 'moment'

Client = require 'modules/client/models/client'

module.exports = class SummaryScreen extends View
  template: require './template'

  initialize: =>
    super
    @model = new Client(mediator.user.get("client"))