_ = require 'underscore'
mediator = require 'mediator'

View = require 'views/base/view'

module.exports = class TermsScreen extends View
  template: require './template'
  region: 'main'

  initialize: =>
    super

    _.get
      url: "/api/pronto/content/bykey/terms"
      success: (res) =>
        res.content = sanitizeHtml(res.content,  { allowedTags,  allowedAttributes});
        @$(".terms-and-conditions").append(res.content)
