NonAuthController = require 'controllers/base/non_auth_controller'

AuthenticateScreen = require 'modules/authentication/views/authenticate-screen'
ResetPasswordScreen = require 'modules/authentication/views/reset-password-screen'

module.exports = class AuthenicationController extends NonAuthController

  login: (p, r, o)->
    @view = new AuthenticateScreen
      region: 'main'
      redirect: if(p.redirect?) then "/#{p.provider}#{p.redirect}" else r.previous?.path

  reset: (p, r, o)=>
    @view = new ResetPasswordScreen
      region: 'main'
      process: p.process
