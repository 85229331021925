View = require 'views/base/view'
$ = require 'jquery'
_ = require 'underscore'
Model = require 'models/base/model'
Chaplin = require 'chaplin'
mediator = require 'mediator'
moment = require 'moment'

alert = require 'lib/alert'

Client = require 'modules/client/models/client'
LocationCollection = require 'collections/common/location-collection'
GroupCollection = require 'collections/common/group-collection'

module.exports = class EditScreen extends View
  template: require './template'
  validator: require './validator'
  autoRender: true

  initialize:(args) =>
    super
    @model = new Client(mediator.user.get('client'))
    if mediator.user.get('client.data')?.smsEnabled?
      @model.set
        smsEnabled: mediator.user.get('client.data')?.smsEnabled
    @listenTo @model, 'change:smsEnabled', (m,v) =>
      @model.set("data.smsEnabled", @model.get("smsEnabled"))

    @view = new Model
      submitted: true
      hasGroup: @model.get("group.id") > 0
      profile1: false
      profile2: false

    dob = new moment(@model.get("dateOfBirth")).startOf("day").add(12, 'h')
    @model.set("dateOfBirth", dob.toDate())

    @dob = new Model
      day: dob.get("date")
      month: dob.get("month")
      year: dob.get("year")

    @locations = new LocationCollection()
    @locations.fetch()

    @groups = new GroupCollection()
    @groups.filterFn = (group) ->
      params = @filterArgs[0]
      return true unless params?
      return params == group.get("locationGuid")

    @groups.fetch(
      success: (collection)=>
        @setGroup(@model.get("group.id") || 0)
        collection.applyFilter(@model.get("locationGuid"))
    )

    @listenTo @dob, 'change', (m, v)=>
      @model.set(dateOfBirth: new moment(m.toJSON()).add(12, 'h').toDate())

    @listenTo @model, 'change:locationGuid', (m, v)=>
      @groups.applyFilter(v)

    @listenTo @model, 'change:title', (m, v)=>
      if v is "Mr"
        @model.set(gender: "M")
      if v is "Ms" || v is "Mrs" || v is "Miss"
        @model.set(gender: "F")

    @listenTo @model, 'change:group.id', (m, v)=> @setGroup(v)

  extraBindings: =>
    locations: @locations
    groups: @groups
    dob: @dob

  gender: (e, v)=>
    @model.set
      gender: e.currentTarget.dataset["gender"]

  setGroup: (v) =>
    group = @groups.get(v)?.toJSON() || {}
    @model.set
      "group.name": group.name

    @view.set
      profile1: group.profile1 || false
      profile2: group.profile2 || false

  save: =>
    return unless @isValid()

    if @view.get("hasGroup") is "false"
      @model.set(group: null)

    _.put(
      url: "/api/pronto/online/client"
      data:
        @model.toJSON()
      success: =>
        mediator.user.set(
          client: @model.toJSON()
        )
        @redirectTo(
          controller: 'modules/client/client',
          action: 'summary'
        )
      error: (r) =>
        if r.responseText
          r = $.parseJSON(r.responseText)
        err = if r.responseStatus?.errorCode then r.responseStatus.errorCode else "Could Not Save Correctly"
        alert(@, "Saving Error", err, =>)
    )
