Chaplin = require 'chaplin'
mediator = require 'mediator'
_ = require 'underscore'
Backbone = require 'backbone'

module.exports = class DeepModel extends Backbone.DeepModel
# module.exports = class DeepModel extends Backbone.Model
   # Mixin an Event Broker
  _(@prototype).extend Chaplin.SyncMachine
  _(@prototype).extend Chaplin.EventBroker

  attributes = ['getAttributes', 'serialize', 'disposed']
  for attr in attributes
    @::[attr] = Chaplin.Model::[attr]
  
  initialize: =>
    super
    @_previousSync = null
    @_syncState = "unsynced"
    @on 'syncStateChange', (args...) =>
      @trigger 'change:_syncState', args
  dispose: ->
    return if @disposed
    Chaplin.Model::dispose.call(this)

  # fetch: (options) =>
  #   @beginSync() 
  #   options ?= {}

  #   options.beforeSend = (xhr)->
  #     if mediator.provider?.get("name")?
  #       xhr.setRequestHeader("x-provider-name", mediator.provider.get("name"))

  #   super(options).done =>
  #     @finishSync()

  parse: (resp, xhr) ->
    resp.result or resp.results or resp