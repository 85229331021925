Chaplin = require 'chaplin'
mediator = require 'mediator'
_ = require 'underscore'

module.exports = class Model extends Chaplin.Model
  _(@prototype).extend Chaplin.SyncMachine
  
  initialize: =>
    super
    @_previousSync = null
    @_syncState = "unsynced"
    @on 'syncStateChange', (args...) =>
      @trigger 'change:_syncState', args
  
  # fetch: (options) =>
  #   @beginSync() 
  #   options ?= {}

  #   options.beforeSend = (xhr)->
  #     if mediator.provider?.get("name")?
  #       xhr.setRequestHeader("x-provider-name", mediator.provider.get("name"))

  #   super(options).done =>
  #     @finishSync()

  parse: (resp, xhr) ->
    resp.result or resp.results or resp