Controller = require 'controllers/base/controller'

HomeScreen = require 'modules/home/views/home-screen'
TermsScreen = require 'modules/booking/views/terms-screen'
NotFoundScreen = require 'modules/home/views/not-found-screen'

module.exports = class HomeController extends Controller

  index: ->
    @view = new HomeScreen
      region: 'main'

  terms: ->
    @view = new TermsScreen
      region: 'main'

  notfound: ->
    @view = new NotFoundScreen
      region: 'main'
