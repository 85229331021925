$ = require 'jquery'
_ = require 'underscore'
Backbone = require 'backbone'
mediator = require 'mediator'

module.exports = class AjaxHelper
  setup: ->
    $.ajaxSetup(cache:false)

    _.mixin
      get: (args) =>
        args.type = "GET"
        @ajaxJSON(args)

      post: (args) =>
        args.type = "POST"
        @ajaxJSON(args)

      put: (args) =>
        args.type = "PUT"
        @ajaxJSON(args)

      del: (args) =>
        args.type = "DELETE"
        @ajaxJSON(args)

    originalSync = Backbone.sync;
    Backbone.sync = (method, model, options) ->
      options.headers = options.headers || {}
      _.extend(options.headers, { "x-provider-name": mediator.provider.get("path")})
      originalSync.call(model, method, model, options)

  ajaxJSON: (args)=>
    if mediator.provider?.get("path")?
      header = { "x-provider-name": mediator.provider.get("path") }

      args.headers = _.extend(header, args.headers)

    $.ajax
      type: args.type || "GET"
      cache: false
      async: args.async || true
      dataType: "json"
      url: args.url || ""
      beforeSend: (request)->
        for k, v of args.headers
          request.setRequestHeader(k, v);

      data: JSON.stringify(args.data || {})
      contentType: "application/json; charset=utf-8"
      success: args.success || =>
      error: args.error || =>
