Chaplin = require 'chaplin'
Model = require './model'
_ = require 'underscore'

mediator = require 'mediator'

module.exports = class Collection extends Chaplin.Collection
  # Mixin a synchronization state machine.
  _(@prototype).extend Chaplin.SyncMachine
  sort_key: undefined
  # Use the project base model per default, not Chaplin.Model
  model: Model

  initialize: =>
    super
    @_previousSync = null
    @_syncState = "unsynced"
    @filtered = @models
    @on 'add remove reset sort', => 
      @finishSync() 

  applyFilter: (args...)=>
    @filterArgs = args
    @filtered = _.filter(@models, @filterFn, this)
    @trigger("filter")

  filterFn: ()=>
    return true

  fetch: (options) =>
    @beginSync() 
    options ?= {}
    options.silent = true
    # options.beforeSend = (xhr)->
    #   if mediator.provider?.get("name")?
    #     xhr.setRequestHeader("x-provider-name", mediator.provider.get("name"))

    options.success = _.wrap (options.success ? ->), (func, args...) =>
      func args...
      @sort()
    return super(options)

  reset:=>
    #safety method incase not called in post
    @beginSync() unless @isSyncing()
    super

  parse: (resp, xhr) ->
    resp.result or resp.results or resp

  comparator: (a, b) =>
    return 0 unless @sort_key?
    keys = @sort_key.split(" ");
    sortKey = keys[0];
    fallback_a = '0'
    fallback_b = '0'
    if sortKey is 'reference'
      fallback_a = a.id
      fallback_b = b.id

    
    ## If sort key is property of an array, then just sort by that first element in the array for each model.
    isArray = if sortKey.indexOf('[') >= 0 then true else false
    if isArray
      arrayName = sortKey.substring(0, sortKey.indexOf('['))
      arrayProperty = sortKey.substring(sortKey.indexOf(']') + 2)
      if a.get(arrayName)? and a.get(arrayName).length > 0 and b.get(arrayName)? and b.get(arrayName).length > 0
        a = a.get(arrayName)[0][arrayProperty] || fallback_a
        b = b.get(arrayName)[0][arrayProperty] || fallback_b
    else
      a = a.get(sortKey) || fallback_a
      b = b.get(sortKey) || fallback_b
    unless isNaN(a) or isNaN(b)
      a = parseFloat(a) || 0
      b = parseFloat(b) || 0
    aIsDate = a isnt '0' and not isNaN(Date.parse(a))
    bIsDate = b isnt '0' and not isNaN(Date.parse(b))

    if aIsDate or bIsDate
      a = if aIsDate then new Date(a) else new Date()
      b = if bIsDate then new Date(b) else new Date()


    if keys.length > 1 and keys[1] = 'DESC'
      return 1 if a < b 
      return -1 if a > b
      return 0
    else
      return 1 if a > b 
      return -1 if a < b
      return 0
