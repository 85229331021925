Chaplin = require 'chaplin'
mediator = require 'mediator'
View = require 'views/base/view'
$ = require 'jquery'
_ = require 'underscore'
moment = require 'moment'
AuthHelper = require 'lib/auth-helper'
alert = require 'lib/alert'
mediator: require 'mediator'
Model = require 'models/base/model'
DeepModel = require 'models/base/deep_model'
Collection = require 'models/base/collection'

module.exports = class AuthenticateScreen extends View
  template: require './template'
  validator: require './validator'
  className: 'authenticate'
  optionNames: View::optionNames.concat ['redirect']

  initialize: =>
    super
    @model ?= new Model()
    @view ?= new Model
      term: ""
      code: ""
      register: false

  extraBindings: =>
    user: mediator.user
    booking: mediator.booking

  search: =>
    window.scrollTo(0,0)
    @model.clear()
    if @getValidationResults("username").validMobile then @model.set("mobileTelephone", @view.get("term"))
    if @getValidationResults("username").validEmail then @model.set("email", @view.get("term"))

    @view.set(submitted:true)

    return unless @isValid("username")

    @publishEvent 'loading:start'
    _.post
      url: "/api/pronto/register/quick"
      data: {term: @view.get('term')}
      success: (count)=>
        @view.set
          isSynced: true
          usersFound: count
          register: count is 0
          allowRegister: mediator.provider.get('allowRegister') || count is 1
          submitted: false

        @$(".panel.active:visible input:first").focus()
        @publishEvent 'loading:finish'

      error: (xhr, err, status) =>
        alert(@, "Quick Search Failed", xhr?.responseJSON?.responseStatus?.message || "", () => @publishEvent 'loading:finish')

  submit: (e)=>
    return unless e.which is 13
    @login()

  login: =>
    @publishEvent 'loading:start'
    @authenticate(@view.get("term"), @model.get("password"))

  authenticate: (username, password)=>
    _.post
      url: "/api/auth/credentials"
      data:
        username: username
        password: password
      success: (res)=>
        authHelper = new AuthHelper(@redirect || "/#{mediator.provider.get('path')}/booking/service")
        if not authHelper.done(res)
          @authenticate(username, password)
        @publishEvent 'loading:finish'

      error: (xhr, err, status) =>
        alert(@, "Login Failed", xhr?.responseJSON?.responseStatus?.message || "", () => @publishEvent 'loading:finish')

  resetPassword: =>
    @publishEvent 'loading:start'
    l = window.location

    _.post
      url: "/api/pronto/user/online/reset"
      data:
        username: @view.get("term")
        host: "#{l.protocol}//#{l.host}"
        path: mediator.provider.get("path")

      success: (res)=>
        alert(@, "Reset Sent", "A reset confirmation message has been sent to either your email address or mobile phone number", () => @publishEvent 'loading:finish')

      error: (xhr, err, status)=>
        alert(@, "Password Reset Request Failed", xhr?.responseJSON?.responseStatus?.message || "", () => @publishEvent 'loading:finish')

  verify: =>
    @publishEvent 'loading:start'
    _.post
      url: "/api/pronto/register/verify"
      data:
        code: @view.get("code")

      success: (res)=>
        @model.set(res)
        @view.set(register:true)
        @publishEvent 'loading:finish'

      error: (xhr, err, status)=>
        alert(@, "Verification Failed", xhr?.responseJSON?.responseStatus?.message || "", () => @publishEvent 'loading:finish')

  register: =>
    window.scrollTo(0,0)
    @model.set
      locationId: mediator.booking?.get("appointment.location.id") || null
    @view.set(submitted:true)
    return unless @isValid()
    @publishEvent 'loading:start'
    _.post
      url: "/api/pronto/register/full"
      data: @model.toJSON()
      success: (res)=>
        @publishEvent 'loading:finish'
        @authenticate(@model.get("email"), @model.get("password"))

      error: (xhr) =>
        alert(@, "Registration Failed", xhr?.responseJSON?.responseStatus?.message || "", () => @publishEvent 'loading:finish')
