var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div rv-show=\"view:notify\" class=\"login-acknowledgement fixed-top-centre text-center skin-default\"><span>You have successfully logged in</span></div><div id=\"outer-page-container\"><div id=\"header-container\"></div><div id=\"page-container\"></div><div class=\"page-footer\"><div class=\"row\"><div class=\"column small-8 medium-5\"><br/><img width=\"80\" src=\"/images/bz_logo.png\"/><br class=\"clearfix\"/><span class=\"footer-copy\">&copy; Copyright Blue Zinc IT Ltd 2017</span></div><div class=\"column show-for-medium-up medium-2 right\"><div class=\"image-block\"></div><img width=\"90\" src=\"/images/tm3_logo.png\"/></div><div class=\"column show-for-medium-up medium-2 right\"><div class=\"image-block\"></div><img width=\"90\" src=\"/images/tm2_logo.png\"/></div><div class=\"column small-4 medium-1 right\"><div class=\"image-block\"></div><img width=\"40\" src=\"/images/pronto_logo.png\"/></div><div class=\"column show-for-medium-up medium-2 right\"><div class=\"image-block\"></div><img width=\"80\" src=\"/images/click_logo.png\"/></div></div></div></div><div rv-show=\"view:loading\" class=\"modal\"><div class=\"loading\"><i class=\"fa fa-spinner fa-spin fa-3x\"></i><div>Loading...</div></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}