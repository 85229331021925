home = 'modules/home/home'
bookings = 'modules/booking/booking'
searching = 'modules/booking/searching'
invoice = 'modules/invoice/invoice'
history = 'modules/history/history'
client = 'modules/client/client'
support = 'modules/support/support'
authentication = 'modules/authentication/authentication'

# Application routes.
module.exports = (match) ->
  match '', controller: home, action: 'index'
  match '/404', controller: home, action: 'notfound'
  match ':provider/terms', controller: home, action: 'terms'

  match ':provider', controller: searching, action: 'service'
  match ':provider/booking', controller: searching, action: 'service'
  match ':provider/booking/service', controller: searching, action: 'service'
  match ':provider/booking/availability', controller: searching, action: 'availability'
  match ':provider/booking/problem', controller: bookings, action: 'problem'
  match ':provider/booking/payment', controller: bookings, action: 'payment'
  match ':provider/booking/confirmation', controller: bookings, action: 'confirmation'

  match ':provider/client/summary', controller: client, action: 'summary'
  match ':provider/client/edit', controller: client, action: 'edit'

  match ':provider/client/history', controller: history, action: 'history'

  match ':provider/invoice', controller: invoice, action: 'invoice'

  match ':provider/login', controller: authentication, action: 'login'
  match ':provider/login/update', controller: authentication, action: 'reset'
  match ':provider/login/reset/:process', controller: authentication, action: 'reset'

  match ':provider/support', controller: support, action: 'support'
