Collection = require 'models/base/collection'
mediator = require 'mediator'
Model = require 'models/base/model'


module.exports = class PractitionerCollection extends Collection
  url: =>
    base = "/api/pronto/system/targets/practitioners/info"
    serviceId = mediator.booking?.get("service.networkServiceId")
    networkId = mediator.booking?.get("service.networkId")
    query = "?networkId=#{networkId}&serviceId=#{serviceId}"
    return base + query if serviceId? && networkId?
    return base