View = require './view'
$ = require 'jquery'

module.exports = class DialogView extends View
  autoRender:true
  autoAttach:true
  className: 'reveal-modal'
  attributes:
    'data-reveal':1
    'data-offset':10
    'data-css-top':20

  attach: =>
    super
    @$el.foundation()
    @$el.on('close', '[data-reveal]', @close)
    @$el.foundation('reveal', 'open')
    #cant remember why this was added but breaks other things
    #$('body').scrollTop(0);

  close: =>
    otherModals = $(".reveal-modal").not(@$el)
    if otherModals.length > 0
      otherModals.foundation('reveal', 'open')
    else
      @$el.foundation("reveal", "close")
    @dispose()
