$ = require 'jquery'
Chaplin = require 'chaplin'
mediator = require 'mediator'

AuthHelper = require 'lib/auth-helper'
Controller = require './controller'

_ = require 'underscore'

module.exports = class AuthController extends Controller

  beforeAction: ()->
    super
    if mediator.user.get('stateUnknown')
      # if state is unknown then the page is loading
      # fresh and will not have a booking object
      # window.location.pathname || window.location.hash
      authHelper = new AuthHelper()

      _.get(
        url:'/api/user/current'
        success: (res)=>
          if res.isAuthenticated is true
            authHelper.done(res)
          else
            @unauthorized()
        error: =>
          @unauthorized()
      )
    else unless mediator.user.get("isAuthenticated")
      @unauthorized()

  unauthorized: =>
    mediator.user.set
      isAuthenticated:false
      stateUnknown:false
      permissions: []
    Chaplin.utils.redirectTo url: "#{mediator.provider.get('path')}/login"
