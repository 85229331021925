var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"row\"><h4> <span>To&nbsp; </span><span rv-text=\"view:mode\"></span><span>&nbsp;your password enter and confirm it in the boxes below</span></h4><div class=\"panel\"><label>Password</label><input type=\"password\" rv-value=\"model:password\" rv-on-keyup=\"vm&gt;immediateSync\" rv-validate=\"vm&gt;password &lt; model:password view:submitted\"/><label>(Again)</label><input type=\"password\" rv-value=\"model:confirm\" rv-on-keyup=\"vm&gt;immediateSync\" rv-validate=\"vm&gt;confirm &lt; model:confirm view:submitted\"/><div class=\"clearfix\"><div rv-on-click=\"vm&gt;submit\" class=\"right button small\">Reset</div></div></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}