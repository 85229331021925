View = require 'views/base/view'
$ = require 'jquery'
Chaplin = require 'chaplin'
mediator = require 'mediator'
moment = require 'moment'
MoveDialog = require 'modules/history/views/move-dialog'
CancellationDialog = require 'modules/history/views/cancellation-dialog'

Client = require 'modules/client/models/client'

AppointmentsHistoryCollection = require 'collections/common/appointment-history-collection'
AppointmentsCollection = require 'collections/common/appointment-collection'

module.exports = class HistoryScreen extends View
  template: require './template'

  initialize: =>
    super
    @subscribeEvent "appointment:cancelled", (model)=>
      @history.set([{id: model.id, status:model.status}], {add:false, remove:false})

    @history = new AppointmentsHistoryCollection()
    @history.comparator = 'startDateTime desc'

    if(mediator.user.get("stateUnknown"))
      @listenTo mediator.user, 'change:stateUnknown', @load
    else
      @load()

  load: =>
    @model = new Client(mediator.user.get("client"))
    @history.fetch(
      data:
        id: @model.get("id")
    )

  extraBindings: =>
    history: @history

  cancel: (e,v) =>
    @subview 'cancellation', new CancellationDialog(
      model: v.appointment
    )

  move: =>
    @subview 'move', new MoveDialog()

  rebookAppointment: =>
    location.reload()
