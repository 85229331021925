View = require 'views/base/view'
$ = require 'jquery'
_ = require 'underscore'
mediator = require 'mediator'
Collection = require 'models/base/collection'
Model = require 'models/base/model'

module.exports = class HeaderView extends View
  autoRender: true
  className: 'header'
  tagName: 'header'
  template: require './templates/header'

  extraBindings: =>
    user: mediator.user
    provider: mediator.provider

  navigate: =>
    #hack because when would u ever use a link to navigate
    @$('.toggle-topbar').click();

  logout: =>
    @navigate()

    _.get(
      url: '/api/auth/logout',
    ).done =>
      mediator.user.clear()
      @redirectTo
        controller: "modules/booking/searching"
        action: "service"
