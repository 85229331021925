var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div rv-on-click=\"vm&gt;confirm\" class=\"close-reveal-modal\">&#215;</div><h3> <span rv-text=\"model:title\"></span></h3><hr/><div rv-text=\"model:message\"></div><hr/><div class=\"right\"><button rv-on-click=\"vm&gt;confirm\" class=\"success\">OK</button></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}