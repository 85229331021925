Model = require 'models/base/model'
DialogView = require 'views/base/dialog_view'

class AlertDialog extends DialogView
  template:require './templates/AlertDialog'

  confirm: =>
    @publishEvent 'alert:confirmed'
    @close()

module.exports = (view, title, message, callback) ->
  view.subscribeEvent 'alert:confirmed', callback
  view.subview 'alertDialog', new AlertDialog
    model: new Model
      title:title
      message:message
