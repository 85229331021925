Collection = require './collection'

module.exports = class PagingCollection extends Collection
  model: require './model'

  parse: (res) =>
    @total = res?.total
    @hasMore = (@total || -1) > (@length + (res.results || res).length)
    @remaining = @total - (@length + (res.results || res).length)
    return res.results or res
