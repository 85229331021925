var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"row\"><h3>Welcome to the Online Bookings, powered by Pronto.</h3><p> </p><span> \nFor further information about how to get this Portal for your business, please go to&nbsp;<a href=\"http://www.insidetm2.com\">this website.</a></span></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}