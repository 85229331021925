Chaplin = require 'chaplin'
mediator = require 'mediator'

DeepModel = require 'models/base/deep_model'
NonAuthController = require 'controllers/base/non_auth_controller'

ServiceScreen = require 'modules/booking/views/service-screen'
AvailabilityScreen = require 'modules/booking/views/availability-screen'

module.exports = class SearchingController extends NonAuthController
  menuTitle: 'booking'

  beforeAction: (p, r, o)->
    super
    unless  (mediator.booking.has("service") || r.action is "service")
      Chaplin.utils.redirectTo(
        controller: 'modules/booking/searching',
        action: 'service',
        params:
          provider: p.provider
      )

  service: (args)->
    @view = new ServiceScreen
      region: 'main'
      booking: new DeepModel(args.booking)

  availability: (args)->
    @view = new AvailabilityScreen
      region: 'main'
      booking: new DeepModel(args.booking)
