ValidationMachine = require 'lib/ValidationMachine'

module.exports = class Validator extends ValidationMachine
  applyRules: =>
    super
    @required 'password'

    @rule 'confirm', =>
      val = @model.get("password") || ""
      retype = @model.get("confirm") || ""
      return null unless @view.get("submitted") || val isnt ""
      return { isValid:false, message:"Cannot be empty" } if retype == ""
      return { isValid:false, message:"Passwords do not match" } if val != retype
      return { isValid:true, message:"" }