_ = require 'underscore'
View = require 'views/base/view'
Model = require 'models/base/model'
mediator = require 'mediator'
Chaplin = require 'chaplin'
alert = require 'lib/alert'

# Site view is a top-level view which is bound to body.
module.exports = class SiteView extends View
  container: 'body'
  id: 'site-container'
  regions:
    header: '#header-container'
    main: '#page-container'
  template: require './templates/site'

  initialize: =>
    super
    $(document).ajaxError((e, xhr)=>
      errorCode = xhr?.responseJSON?.responseStatus?.errorCode
      if xhr.status is 401 && errorCode is "External Auth Failure"
        mediator.user.set
          isAuthenticated:false
          stateUnknown:false
        message = xhr?.responseJSON?.responseStatus?.message
        message = (message || "") + ". This may be due to the page being inactive for too long and logging out for security"
        alert(@, "Unauthorised", message, () => @publishEvent 'loading:finish')
        Chaplin.utils.redirectTo url: "#{mediator.provider.get('path')}/login"

    )
    @subscribeEvent 'loading:start', => @view.set loading:true
    @subscribeEvent 'loading:finish', => @view.set loading:false

    @view = new Model
      notify:false
      loading:false

    @listenTo mediator.user, "change:isAuthenticated", (m, v)=>
      return unless v is true
      @view.set(notify:true)
      _.delay((()=> @view.set(notify: false)), 2000)
