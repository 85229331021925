ValidationMachine = require 'lib/ValidationMachine'
mediator = require 'mediator'

module.exports = class Validator extends ValidationMachine
  applyRules: =>
    super
    @required 'title'
    @required 'forename'
    @required 'surname'
    @required 'addressLine1'
    @required 'postcode'

    @requiredIf 'homeTelephone', =>
      work = (@model.get("workTelephone") || "").trim()
      mobile = (@model.get("mobileTelephone") || "").trim()
      return work is "" && mobile is ""

    @requiredIf 'workTelephone', =>
      home = (@model.get("homeTelephone") || "").trim()
      mobile = (@model.get("mobileTelephone") || "").trim()
      return home is "" && mobile is ""

    @requiredIf 'mobileTelephone', =>
      work = (@model.get("workTelephone") || "").trim()
      home = (@model.get("homeTelephone") || "").trim()

      provider = mediator.provider.toJSON()
      sms = provider?.license?.hasText && provider?.allowSMS && @model.get("smsEnabled")

      return work is "" && home is "" || sms

    @requiredIf 'group.id', => return @view.get("hasGroup") is "true"
    @requiredIf 'groupReference', => return @view.get("profile1")
    @requiredIf 'insuranceNumber', => return @view.get("profile2")

    @rule 'email', =>
      val = @model.get("email") || ""
      emailValFilter = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      return { isValid:false, message:"Alternative email is not formatted correctly. e.g. example@email.com" } unless emailValFilter.test(val)
      return { isValid:true, message:"" }
