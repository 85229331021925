Chaplin = require 'chaplin'
mediator = require 'mediator'
View = require 'views/base/view'
$ = require 'jquery'

Model = require 'models/base/model'
DeepModel = require 'models/base/deep_model'
Collection = require 'models/base/collection'

module.exports = class PaymentScreen extends View
  template: require './template'
  optionNames: View::optionNames.concat ['booking']

  initialize: =>
    super
    @model ?= new Model()
    @publishEvent 'loading:finish'
    
  select: (e, v)=>
    mediator.booking.set
      payment:v.model.toJSON()

    @redirectTo(
      controller: 'modules/booking/booking',
      action: 'confirmation',
      # params:
      #   booking: @booking.toJSON()
    )
