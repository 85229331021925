var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"row\"><h3>My Invoices</h3><table style=\"width:100%;\"><thead><tr class=\"centralise\"><th>Ref</th><th>Date</th><th>Total</th><th>Amount Paid</th><th>Status</th><th></th></tr></thead><tbody><tr rv-each-invoice=\"invoices:models\" class=\"centralise\"><td rv-text=\"invoice:reference\"></td><td rv-text=\"invoice:date | date\"></td><td rv-text=\"invoice:grandTotal | currency\"></td><td rv-text=\"invoice:amountPaid | currency\"></td><td><span rv-show=\"invoice:status | eq F\">Paid</span><span rv-show=\"invoice:status | eq P\">Part Paid</span><span rv-show=\"invoice:status | eq U\">Unpaid</span></td><td><a rv-href=\"invoice:id | before /api/pronto/invoice/download/ | after .csv | provider \" class=\"button small right no-margin\">Download</a></td></tr></tbody></table></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}