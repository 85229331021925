Chaplin = require 'chaplin'
mediator = require 'mediator'
View = require 'views/base/view'
$ = require 'jquery'
defer = require 'jquery'
_ = require 'underscore'

alert = require 'lib/alert'

Model = require 'models/base/model'
DeepModel = require 'models/base/deep_model'
Collection = require 'models/base/collection'

LocationCollection = require 'collections/common/location-collection'
ServiceCollection = require 'collections/common/service-collection'

module.exports = class ServiceScreen extends View
  autoRender: true
  className: 'home-page'
  template: require './template'

  initialize: =>
    super
    @booking ?= new DeepModel()

    @locations  = new LocationCollection()
    @services   = new ServiceCollection()
    @services.comparator = "sortIndex"
    @services.fetch(
      data: {onlineOnly: true}
    )

    mediator.provider.on 'change', => @services.reset(@services.toJSON())

    @classes = new Collection()
    @classes.url = "/api/pronto/classes/search"
    @classes.fetch(
      type: "POST"
    )

  extraBindings: =>
    classes: @classes
    services: @services
    device: mediator.device

  select: (e, v)=>
    mediator.booking.set
      service:v.service.toJSON()

    @redirectTo(
      controller: 'modules/booking/searching',
      action: 'availability',
    )

  register: =>
    alert(@, "Not Implement", "Class booking has not yet been implement", () =>)
