Chaplin = require 'chaplin'
mediator = require 'mediator'
View = require 'views/base/view'
$ = require 'jquery'
_ = require 'underscore'
moment = require 'moment'

alert = require 'lib/alert'

Model = require 'models/base/model'
DeepModel = require 'models/base/deep_model'
Collection = require 'models/base/collection'

LocationCollection = require 'collections/common/location-collection'
PractitionerCollection = require 'collections/common/practitioner-collection'
AppointmentsCollection = require 'collections/common/appointment-collection'
SessionCollection = require 'collections/common/session-collection'

module.exports = class AvailabilityScreen extends View
  template: require './template'
  hidefilter: true

  initialize: =>
    super
    appt = mediator.booking.get("appointment")
    if appt?
      _.del(url: "/api/pronto/appointments/provisional/#{appt.id}").done( =>
        mediator.booking.set(appointment:null)
      )

    @serviceId = mediator.booking.get("service.networkServiceId")
    @networkId = mediator.booking.get("service.networkId")

    @view = new Model
      mode: "filter"

    @filter = new Model
      location: mediator.booking.get("appointment.locationGuid") || mediator.user.get("client.locationGuid") || ""
      practitioner: ""
      gender: "X"

    @sessions = new SessionCollection()
    @locations = new LocationCollection()
    @appointments = new AppointmentsCollection()
    @practitioners = new PractitionerCollection()

    @locations.fetch(
      success: (collection, resp) =>
        if collection.length is 1
          return @locate(null, {location:collection.at(0)})
        if @filter.get("location") isnt ""
          return @locate(null, {location:collection.find((l) => l.get("onlineGuid") is @filter.get("location"))})
    )

    @practitioners.filterFn = (pract) ->
      params = @filterArgs[0]
      return true unless params?
      return (params.gender is pract.get("gender") || params.gender is "X") &&
              params.location is pract.get("locationId")

    @practitioners.fetch().done(=> @practitioners.applyFilter(@filter.toJSON()))

    @appointments.comparator = "startDateTime"

    @appointments.filterFn = (appt)->
      params = @filterArgs[0]
      return true unless params?
      loc = params.location || ""
      prac = params.practitioner || ""
      params.gender = "X" if params.gender is "N"

      return (loc is appt.get("location.id") || loc is "") &&
             (prac is appt.get("practitioner.id") || prac is "") &&
             (params.gender is appt.get("practitioner.gender") || params.gender is "X")

    @listenTo @filter, 'change:practitioner', (m, v, o)=>
      return if o.isRecursive
      @filter.set
        gender: "X"
        practitionerName: @practitioners.find((p) -> p.get("practitionerId") is v)?.get("name")
        {isRecursive: true}

      @appointments.applyFilter(m.toJSON())

    @listenTo @sessions, 'filter', @search


  extraBindings: =>
    filter: @filter
    booking: mediator.booking
    locations: @locations
    sessions: @sessions
    appointments: @appointments
    practitioners: @practitioners
    vm: @
    user:mediator.user

  attach: =>
    super
    $(".filter-row").hide()

  showfilter: (e, v) =>
    if @hidefilter is true
      $(".filter-row").slideDown()
      @hidefilter = false
    else
      $(".filter-row").slideUp()
      @hidefilter = true

  gender: (e, v)=>
    @filter.set
      gender: @$(e.currentTarget).data("gender")
      practitioner: ""
      practitionerName: ""
      {isRecursive: true}

    @appointments.applyFilter(@filter.toJSON())

  moveTo: (e, v)=>
    $target = @$(e.currentTarget)

    # Internet explorer fix as it dosent do pointer events
    if($target.hasClass("disabled"))
      e.preventDefault()
      return

    date = v.session
    period = $target.parents("[data-period]").data("period")

    @$(".appointment").hide()
    @$(".appointment[data-date=#{date}][data-period=#{period}]").show()

    @view.set
      mode:"results"
      period: period
      date: date

  back: =>
    @view.set
      mode:"filter"
      date: null

  search: =>
    @view.set
      hasMore: !@sessions.hasMore
      hasLess: !@sessions.hasLess

    location = @filter.get("location")

    if location is ""
      location = null
      @appointments.unsync()
      return

    @appointments.fetch(
      type:"POST"
      contentType: "application/json"
      data: JSON.stringify(
        From: new moment(@sessions.dates[0]).startOf("day").toDate()
        To: new moment(@sessions.dates[@sessions.dates.length - 1]).endOf("day").toDate()
        ServiceId: @serviceId
        NetworkId: @networkId
        Groupings: [
            { GroupBy: 0, MaxNumberOfResults: -1, Randomise:false }
            { GroupBy: 1, MaxNumberOfResults: -1, Randomise:false }
            { GroupBy: 2, MaxNumberOfResults: -1, Randomise:true }
          ]
      )
      success: =>
        @appointments.applyFilter(@filter.toJSON())
    )

  locate: (e, v)=>
    @sessions.unsync()
    @filter.set
      locationName: v.location?.get("name") || ""
      location: v.location?.get("onlineGuid") || ""
      practitioner: ""
    @appointments.applyFilter(@filter.toJSON())
    @practitioners.applyFilter(@filter.toJSON())

    return unless v.location?

    @sessions.filterStartDate = new moment()
    @sessions.filterEndDate = new moment()
    @sessions.fetch(
      type:"POST"
      contentType: "application/json"
      data:JSON.stringify
        from: new moment().toDate()
        to: new moment().add('days', 200).toDate()
        locationId: v.location.get('onlineGuid')
    )

  reserve: (e, v)=>
    window.scrollTo(0,0)
    @publishEvent 'loading:start'
    @view.set(loading:true)
    _.post
      url: "/api/pronto/appointments/provisional"
      data: _.extend(v.appointment.toJSON(), {patientId: mediator?.user?.get("client.id")})
      success: (res)=>
        mediator.booking.set
          appointment: _.extend(v.appointment.toJSON(), res.appointment)

        if(mediator?.user?.get("client.id")?)
          @redirectTo(
            controller: 'modules/booking/booking',
            action: 'problem'
          )
        else
          @redirectTo(
            controller: 'modules/authentication/authentication',
            action: 'login'
            params:
              redirect: "/booking/problem"
          )
        @publishEvent 'loading:finish'

      error: (xhr, err, status) =>
        @publishEvent 'loading:finish'
        alert(@, "Reservation Failed", xhr?.responseJSON?.responseStatus?.message || "", () =>)

  forward: =>
    @sessions.nextDate()
    @search()

  backward: =>
    @sessions.prevDate()
    @search()
