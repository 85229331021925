_ = require 'underscore'
mediator = require 'mediator'

Model = require 'models/base/model'
module.exports = class ValidationMachine

  rule: (key, fn)->
    @rules.push(key)
    @[key] = fn

  isValid: (name) ->
    if @rules?
      if name?
        return @[name]()?.isValid is true

      for key in @rules
        result = @[key]()
        continue if result?.skip
        return false unless result?.isValid
    return true

  getValidationResults: (name) ->
    if @rules?
      if name?
        return @[name]() || false

  applyRules: =>
    @view ?= new Model()
    @rules ?= []

  applyGraphics: (selector = "[rv-validate]")->
    valid = @$(selector)
    _.each(valid, (e) =>
      $e = @$(e)
      $e.wrap("<div class='valid'></div>")
      $e.before("<div class='btn'>&nbsp;</div>")
      if mediator.device.get("isTouch")
        $e.after("<div class='error-message'><div class='message'/><div class='arrow'></div>")
      $e.parent().attr("rv-validate", $e.attr("rv-validate"))
      $e.removeAttr("rv-validate")
    )

  required: (field, message, model) =>
    @rule field, =>
      model ?= "model"
      val = (@[model]?.get(field) || "")?.trim()
      return null if @[model]?.isNew() && !@view.get("submitted") && val is ""
      return { isValid:false, message: message || "Enter a #{field.replace(/([A-Z])/g, ' $1').toLowerCase()}" } if val is ""
      return { isValid:true, message:"" }

  requiredIf: (field, ifCallback, message) =>
    @rule field, =>
      return { isValid:true, message:"" } unless ifCallback()
      model = "model"
      val = (@[model]?.get(field) || "")?.trim()
      return null if @[model]?.isNew() && !@view.get("submitted") && val is ""
      return { isValid:false, message: message || "Enter a #{field.replace(/([A-Z])/g, ' $1').toLowerCase()}" } if val is ""
      return { isValid:true, message:"" }

  min: (field, minValue, message, model) =>
    @rule field, =>
      model ?= "model"
      val = (@[model]?.get(field) || 0)
      return null if @[model]?.isNew() && !@view.get("submitted") && val < minValue
      return { isValid:false, message: message || "Make sure #{field} is higher than #{minValue}" } if val < minValue
      return { isValid:true, message:"" }

  requiredDate: (field) =>
      @rule field, =>
        val = @model.get(field)
        return null if @model?.isNew() && !@view.get("submitted") && !val?
        return { isValid:false, message:"Enter a #{field}" } if !val?
        return { isValid:true, message:"" }

  requiredDateOfBirth: (field) =>
      @rule field, =>
        val = @model.get(field)
        return null if @model?.isNew() && !@view.get("submitted") && !val?
        return { isValid:false, message:"Enter a #{field}" } if !val?
        return { isValid:false, message:"Date of Birth cannot be in the future." } if val > new Date()
        return { isValid:true, message:"" }

  clearRules:=>
    @rules = []
    delete @rules
