ValidationMachine = require 'lib/ValidationMachine'
mediator = require 'mediator'

module.exports = class Validator extends ValidationMachine
  applyRules: =>
    super

    @requiredIf 'title', => return @view.get("register") is true
    @requiredIf 'forename', => return @view.get("register") is true
    @requiredIf 'surname', => return @view.get("register") is true
    @requiredIf 'password', => return @view.get("register") is true

    @rule 'confirm', =>
      return { isValid:true, message:"" } unless @view.get("register") is true
      val = @model?.get("confirm") || ""
      return null if (@model?.isNew() && !@view.get("submitted") && val is "")
      return { isValid:false, message:"Passwords do not match" } unless @model.get("password") is @model.get("confirm")
      return { isValid:true, message:"" }

    @rule 'username', =>
      val = (@view.get("term") || "").trim()
      return null unless @view.get("submitted") || val isnt ""
      email = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      mobile = /^\d+$/
      provider = mediator.provider?.toJSON()
      if provider?.license?.hasText is true && provider?.allowSMS
        return { isValid:false, message:"Enter an email or mobile number. e.g. example@email.com or 07123456789" } unless (email.test(val) || mobile.test(val))
      else
        return { isValid:false, message:"Enter an email address. e.g. example@email.com" } unless email.test(val)

      return { isValid:true, validEmail:email.test(val), validMobile:mobile.test(val), message:"" }

    @rule "mobileTelephone", =>
      return { isValid:true, message:"" } unless @view.get("register") is true
      val = (@model?.get("mobileTelephone") || "")?.trim()
      mobile = /^\d+$/
      return null if @model?.isNew() && !@view.get("submitted") && val is ""
      return { isValid:false, message:"Enter a mobile number. e.g. 07123456789" } unless mobile.test(val)
      return { isValid:true, message:"" }

    @rule "email", =>
      return { isValid:true, message:"" } unless @view.get("register") is true
      val = (@model?.get("email") || "")?.trim()
      email = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      return null if @model?.isNew() && !@view.get("submitted") && val is ""
      return { isValid:false, message:"Enter an email address. e.g. example@email.com" } unless email.test(val)
      return { isValid:true, message:"" }
