$ = require 'jquery'
_ = require 'underscore'
Chaplin = require 'chaplin'
mediator = require 'mediator'
View = require 'views/base/view'

alert = require 'lib/alert'

Model = require 'models/base/model'
Collection = require 'models/base/collection'

module.exports = class ConfirmationScreen extends View
  template: require './template'

  initialize: =>
    super
    @model ?= new Model()
    @view = new Model({
      terms: false
      fixed: false
    })

    if(mediator.user.get("stateUnknown"))
      @listenTo mediator.user, 'change:stateUnknown', @load
    else
      @load()

  load: =>
    @publishEvent 'loading:start'
    if mediator.user.get('client.data')?.smsEnabled
      mediator.user.get("client").smsEnabled = mediator.user.get('client.data').smsEnabled
    @view.set(sms: mediator.user.get("client").smsEnabled)

    _.get
      url: "/api/pronto/content/bykey/cancellation-policy"
      success: (res) => 
        res.content = sanitizeHtml(res.content,  { allowedTags,  allowedAttributes});
        @view.set(policy: res.content)

    _.post(
      url: '/api/pronto/clients/history/limit'
      data: { id: mediator.user?.get("client.id") }
      success: (res)=>
        @view.set(limited: res.result)
        appt = mediator.booking.get("appointment")
        if @view.get("limited") is true
          _.del(url: "/api/pronto/appointments/provisional/#{appt.id}").done( =>
            mediator.booking.set(appointment:null)
          )
        @publishEvent 'loading:finish'
      error: (err) =>
        console.log err
    )

  extraBindings: =>
    booking: mediator.booking
    user: mediator.user

  book: =>
    window.scrollTo(0,0)
    unless @view.get("terms")
      alert(@, "More information required", "Please accept the terms and conditions", () =>)
      return

    @publishEvent 'loading:start'

    id = mediator.booking.get("appointment.id")

    _.put(
      url: "/api/appointments/#{id}/confirm"
      data:
        id: id
        locationId: mediator.booking.get("appointment.locationId")
        practitionerId: mediator.booking.get("appointment.practitionerId")
        customer: mediator.user.get("client")
        case: mediator.booking.get("problem")
        sms: @view.get("sms")

      success: (res)=>
        if _.isEmptyGuid(mediator.user.get("client.locationGuid"))
          mediator.user.set("client.locationGuid", mediator.booking.get("appointment.locationId"))

        @view.set "terms", false

        alert(@, "Appointment Booked", "Your appointment has been successfully booked, you will receive a confirmation email shortly.", () =>
          @publishEvent 'loading:finish'
          mediator.booking.clear()
          @redirectTo
            controller: 'modules/history/history'
            action: 'history'
        )

      error: (xhr, err, status) =>
        @view.set(loading: false)

        message = xhr?.responseJSON?.responseStatus?.message || "A system issue occurred and the appointment could not be booked"
        message = "The system is busy, please try again later" if xhr.status is 429
        message = "Sorry we seem to be having some technical problems. Please try again later." if xhr.status is 500

        alert(@, "There was a problem making your booking", message, () =>
          @publishEvent 'loading:finish'
          @redirectTo(
            controller: 'modules/booking/searching',
            action: 'service',
          )
        )
    )

  attach: =>
    super
    $(window).on('scroll', @onScroll)

  dispose: =>
    super
    $(window).off('scroll')

  onScroll: () =>
    state = $('.inline-confirmation')[0].getBoundingClientRect().top < 0
    return if @view.fixed is state
    @view.set(fixed: state)
