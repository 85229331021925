var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<h2>Move Appointment.</h2><p class=\"lead\">Are you sure you want to reschedule this appointment?</p><p>By moving this appointment, this may result in a cancellation charge. </p><ul class=\"button-group radius round\"><li><div rv-on-click=\"vm&gt;rebookAppointment\" class=\"button small\">Yes</div></li><li><div rv-on-click=\"vm&gt;close\" class=\"button small\">No</div></li></ul><a class=\"close-reveal-modal\">&#215;</a>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}